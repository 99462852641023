
.loader-wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(255,255,255, 0.4);
    overflow-x: hidden;
    overflow-y: hidden;
    

}  

.loader-wrapper .loader {
    margin: auto;
    margin-top: 30%;
    border: 7px solid transparent;
    border-radius: 50%;
    border-top: 7px solid #ec6607;
    border-right: 7px solid #ec6607;
    border-bottom: 7px solid #ec6607;
    border-left: 7px solid #fff;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }