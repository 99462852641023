html, body, #root {
    min-height: 100%;
    height: 100%;
    width: 100%;
    margin: 0;
}
* {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 13px;
}
