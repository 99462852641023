.notFound {
    max-width: 600px;
    margin: auto;
    text-align: center;
    margin-top: 100px;
}

.notFound span {
    font-size: 100px;
    color: #ec6607;
    font-weight: 700;
    /* text-shadow: 3px 3px 0 #323232, 
                3px 3px 0 #323232; */
}
.notFound h1 {
    font-size: 30px;
    color: #323232;
    margin-bottom: 10px;
}

.notFound p {
    font-size: 16px;
    margin: 0px 0px;
}