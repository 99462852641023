.login {
    height: 100%;
    width: 100%;
    background-image: url("/public/img/backgrounds/cordel_background_image.png");
    background-repeat: no-repeat;
    background-size: cover ;
}
.login .layer {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.7);
}

.login .layer .version {
    bottom: 0px;
    position: absolute;
    margin-left: 10px;
    color: white;
}

.login .login-container {
    width: 45%;
    float: right;
}

.login img {
    height: 100px;
}
.login h1 {
    color: #fff;
    margin-top: 150px;
    font-size: 25px;
}
.login label {
    color: #fff;
    display: block;
    font-size: 14px;
}
.login input {
    display: block;
    padding: 7px 10px;
    background-color: #fff;
    width: 300px;
    border-radius: 3px;
    border: none;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 14px;
}
.login input[type="submit"] {
    display: block;
    padding: 7px 10px;
    background-color: #ec6607;
    width: auto;
    color: #fff;
    font-weight: 700;
    margin-top: 15px;
    font-size: 14px;
}